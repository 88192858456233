<template>
	<div class="news-type-line-select">
		<div class="news-type-line-select__wrapper">
			<div
				v-for="item in selectItems"
				:key="item.id"
				class="news-type-line-select__item"
				:class="{
					'news-type-line-select__item-text': true,
					'news-type-line-select__item-divider': divider,
            		'news-type-line-select__item-text-active': modelValue === item.id
          		}"
				@click="onChangeSelect(item.id)">
				{{ item.name }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "NewsSwitcher",
	props: {
		modelValue: {
			type: [String, Number, null],
			required: true,
		},
		selectItems: {
			type: Array,
			required: true,
		},
		divider: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		onChangeSelect(value) {
			this.$emit('update:modelValue', value);
			this.$emit('change', value);
		},
	},
};
</script>

<style lang="scss" scoped>
.news-type-line-select {
	&__wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
	}

	&__item {
		position: relative;

		&:not(:first-child) {
			padding-left: 7px;
		}

		&-divider {
			&:not(:first-child)::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 1px;
				height: 10px;
				background-color: #344859;
			}
		}
	}

	&__item-text {
		line-height: 14px;
		opacity: 0.5;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		&:hover {
			opacity: 1;
		}

		&-active {
			opacity: 1;
		}
	}
}
</style>
